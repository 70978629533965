










































import { Component, Vue } from 'vue-property-decorator'
import ReportService, { GenerateType } from '@/shared/modules/report/ReportService'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import __ from '@/shared/helpers/__'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import FormField from '@/shared/components/form/FormField.vue'
import FormBase from '@/shared/classes/form/form-base'
import moment from 'moment'
import _ from 'lodash'
import { downloadFile } from '@/shared/helpers/download-file.helper'
import PageHeader from '@/admin/components/PageHeader.vue'
import { DataTableHeader } from 'vuetify'
import toggleablePermissions from '@/shared/helpers/toggleable-permissions.helper'

@Component({
  components: { PageHeader, FormField },
  methods: { __ }
})
export default class AllHours extends Vue {
  types = GenerateType
  fromField: Field = new Field()
    .setType(FieldTypes.datePicker)
    .setKey('from')
    .setTitle(__('company.views.reports.index.tabs.all-hours.form.fields.from'))
    .setDense(true)
    .setSize(FieldSizes.half)
  toField: Field = new Field()
    .setType(FieldTypes.datePicker)
    .setKey('to')
    .setTitle(__('company.views.reports.index.tabs.all-hours.form.fields.to'))
    .setDense(true)
    .setSize(FieldSizes.half)
    .setMeta({
      minKey: () => 'from'
    })
  projectsField: Field = new SearchableField()
    .isMultiple()
    .setKey('project_uuids')
    .setTitle(__('company.views.reports.index.tabs.all-hours.form.fields.projects'))
    .setDense(true)
    .loadItems({
      endpoint: `company/${ this.company.slug }/projects`,
      value: 'uuid',
      title: 'name',
      perPage: 20,
      params: {
        permission: toggleablePermissions.reports.notAssignedProjects,
        withTrashed: true,
      }
    })
  employeesField: Field = new SearchableField()
    .isMultiple()
    .setKey('employee_uuids')
    .setTitle(__('company.views.reports.index.tabs.all-hours.form.fields.employees'))
    .setDense(true)
    .loadItems({
      endpoint: `company/${ this.company.slug }/employees`,
      value: 'uuid',
      title: 'full_name',
      perPage: 20
    })

  loading: GenerateType | null = null

  form: FormBase = new FormBase()
    .setData({
      from: moment.utc().startOf('month').format('YYYY-MM-DD'),
      to: moment.utc().endOf('month').format('YYYY-MM-DD'),
      employee_uuids: [],
      project_uuids: [],
    })

  data: any = null

  generate(type: GenerateType) {
    _.set(this.form.data, 'type', type)

    ReportService.allHours(this.company, this.form.data)
      .then((response: any) => {
        this.form.setErrors({})

        if (type === GenerateType.pivot) {
          this.data = response.data
          return
        }

        downloadFile(response, `all-hours-report.${ this.form.data.type }`)
      })
      .catch((error: any) => this.form.catchErrors(error))
  }

  get projectHeaders(): DataTableHeader[] {
    return [
      {
        value: 'id',
        text: __('company.views.reports.index.tabs.all-hours.projects-table.headers.id'),
        sortable: false,
        divider: true,
      },
      {
        value: 'project_name',
        text: __('company.views.reports.index.tabs.all-hours.projects-table.headers.project-name'),
        sortable: false,
        divider: true,
      }
    ]
  }

  get projectItems(): any {
    return Object.keys(this.data.projects).map((uuid: string) => ({
      id: this.data.projects[uuid].unique_id,
      project_name: this.data.projects[uuid].name,
    }))
  }

  get headers(): DataTableHeader[] {
    return [
      {
        value: 'name',
        text: __('company.views.reports.index.tabs.all-hours.users-table.headers.projects'),
        sortable: false,
        align: 'end',
        width: 200,
        divider: true,
      },
      ...Object.keys(this.data.projects).map((uuid: string): DataTableHeader => ({
        value: uuid,
        text: this.data.projects[uuid].unique_id,
        sortable: false,
        align: 'center',
        divider: true,
      })),
      {
        value: 'total',
        text: __('company.views.reports.index.tabs.all-hours.users-table.headers.total'),
        sortable: false,
        align: 'center',
        divider: true,
      }
    ]
  }

  get items(): any {
    return Object.keys(this.data.users).map((uuid: string) => {
      const item: any = {
        name: this.data.users[uuid].name,
        total: this.data.users[uuid].total,
      }
      Object.keys(this.data.projects).forEach((projectUuid: string) => {
        item[projectUuid] = _.get(this.data.users[uuid].projects, `${ projectUuid }.total`, '')
      })

      return item
    })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
